<template>
  <div class="purchase">
    <div class="bidd">
      <div class="bidding">
        <div class="bidding_title">物流竞价</div>
        <!-- 发布竞价销售信息 -->
        <div class="release hy-btn" @click="addClick">发布物流竞价信息</div>
      </div>

      <!-- 物流竞价我发布的 -->
      <div class="biddings">
        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div class="item_goodsName">
              <div>{{ item.lineName }}</div>
              <div style="line-height: 40px; margin-right: 10px">
                {{ item.createTime }}
              </div>
              <div>
                <div
                  class="title_but"
                  v-if="item.biddingStatus == 0 && item.auditStatus == 1"
                >
                  未开始
                </div>
                <div
                  class="title_but"
                  v-if="item.auditStatus == 0 && item.biddingStatus == 0"
                >
                  待审核
                </div>

                <div
                  class="title_but"
                  v-if="item.biddingStatus == 0 && item.auditStatus == 2"
                >
                  审核拒绝
                </div>

                <div
                  class="title_but"
                  v-if="item.biddingStatus == 1 && item.auditStatus == 1"
                  style="color: #139102"
                >
                  进行中
                </div>
                <div
                  class="title_but"
                  v-if="item.biddingStatus == 2 && item.auditStatus == 1"
                  style="color: #d0111a"
                >
                  已流拍
                </div>
                <div
                  class="title_but"
                  v-if="item.biddingStatus == 3 && item.auditStatus == 1"
                >
                  拍卖成功
                </div>
                <div class="title_but" v-if="item.biddingStatus == 4">
                  已关闭
                </div>
              </div>
            </div>
            <div style="display: flex">
              <!-- <div style="line-height: 40px; margin-right: 10px">
                {{ item.createTime }}
              </div> -->
              <div
                class="title_but"
                style="border: 1px solid rgb(204, 204, 204); margin-bottom: 5px;padding:10px"
                @click.stop="closeDemand(item)"
                v-if="item.auditStatus == 0 && item.biddingStatus == 0||item.biddingStatus == 0 && item.auditStatus == 1"
              >
                关闭需求
              </div>
            </div>
          </div>
          <div class="item_im">
            <div>
              <div style="margin: 10px 0">货物名称：{{ item.goodsName }}</div>
              <div>发运量：{{ item.monthFreightVolume }}万吨</div>
            </div>
            <div class="item_name">
              <div style="margin-right: 10px">
                竞价限价： <span>￥</span><span>{{ item.floorPrice }}</span
                >元/吨
              </div>
              <div class="item_images">
                <span v-if="item.taxIncluded == 0">含税</span>
                <span v-if="item.taxIncluded == 1">不含税</span>
              </div>
            </div>
          </div>

          <!-- <div class="item_num">
            <div>
              数量：<span>{{ item.stockCount }}(吨)</span>
            </div>
            <div>竞价开始日期：{{ item.deliveryStartTime }}</div>
            <div>竞价结束日期：{{ item.deliveryEndTime }}</div>
          </div> -->
          <div class="Delivery">出发地：{{ item.originPlace }}</div>
          <div class="place">目的地：{{ item.deliveryPlace }}</div>

          <div class="item_time">
            <div>
              竞价时间：{{ item.biddingStartTime }} ~ {{ item.biddingEndTime }}
            </div>
            <div v-if="item.submitCount > 0" class="submitCountNums">
              已有{{ item.submitCount }}人提交资料
            </div>
          </div>
        </div>

        <div class="allpage">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 发布竞价销售信息 -->
      <!-- <div class="release" @click="addClick">发布竞价采购信息</div> -->
    </div>
  </div>
</template>

<script>
import { tradeGoods, setclose } from "@/api/public.js";
import * as types from "@/store/action-types";
import Vue from "vue";
import { listTbGoods } from "@/api/Logistics.js";
export default {
  data() {
    return {
      readlist: [],
      timer: null,
      time: 0,
      page: 1,
      size: 10,
      total: 0,
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    addClick() {
      this.$router.push({
        path: "/PublishLogistics",
      });
    },
    async handleCurrentChange(e) {
      console.log(e,'22222222')
      // let id = Vue.ls.get(types.enterpriseId);
      this.page=e
      this.getlist()
      // let config = {
      //   pageNum: this.page,
      //   pageSize: this.size,
      //   searchType: 1,
      //   enterpriseId: id,
      // };
      // let res = await listTbGoods(config);
      // if (res.code == 0) {
      //   let _this = this;
      //   let newlist = res.data.list;
      //   _this.readlist = newlist;
      //   _this.total = res.data.total;
      // }
    },
    async getlist() {
      let id = Vue.ls.get(types.enterpriseId);
      let config = {
        pageNum: this.page,
        pageSize: this.size,
        searchType: 1,
        enterpriseId: id,
      };
      let res = await listTbGoods(config);
      if (res.code == 0) {
        let newlist = res.data.list;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.createTime) {
            item.createTime = item.createTime.replace("T", " ");
          }
          if (item.biddingEndTime) {
            item.biddingEndTime = item.biddingEndTime.replace("T", " ");
          }
          if (item.biddingStartTime) {
            item.biddingStartTime = item.biddingStartTime.replace("T", " ");
          }
          //   if (item.delFlag == 0) {
          //     list.push(item);
          //   }
          //   if (item.endTime) {
          //     this.timer = setInterval(() => {
          //       this.time = this.time + 1000;
          //       let a = item.endTime - this.time;
          //       item.endTime = this.toHHmmss(a);
          //     }, 1000);
          //   }
        });
        this.readlist = newlist;
        this.total = res.data.total;
      }
    },
    toHHmmss(data) {
      var time;
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((data % (1000 * 60)) / 1000);
      time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);
      return time;
    },
    closeDemand(item) {
      if (!this.beforhandle("personalCenter:biddingPurchase:publish:close"))
        return;
      this.$confirm("关闭后商品将无法竞拍", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setclose(item.goodsId).then((res) => {
            // console.log(res);
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "关闭成功!",
              });
              this.getlist();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    ItemClick(item) {
      this.$router.push({
        path: "/logisticsDetailss",
        query: {
          goodsId: item.goodsId,
          // selectOrderType: 2,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.purchase {
  max-width: 1200px;
  margin: 20px auto 0;
}

.bidd {
  // width: 80%;
  // display: flex;
  // justify-content: space-between;
  // margin: 0 auto;
  .bidding {
    display: flex;
    justify-content: space-between;
    background: #e5e5e5;
    padding: 10px;
    .bidding_title {
      font-size: 18px;
      font-weight: bold;
      line-height: 40px;
    }
  }
  .biddings {
    // width: 70%;
    // margin-top: 10px;
    background: white;
    padding: 10px;
    .item {
      width: 100%;
      border: 1px solid black;
      padding: 10px;
      margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        // padding-bottom: 10px;
        .item_goodsName {
          display: flex;
          line-height: 40px;
          :nth-child(1) {
            font-size: 18px;
            color: black;
            font-weight: bold;
          }
          :nth-child(2) {
            margin-left: 13px;
          }
        }
        .title_but {
          //   padding: 10px;
          // border: 1px solid #ccc;
          cursor: pointer;
        }
      }
      .item_name {
        display: flex;
        color: red; // justify-content: space-between;
        font-weight: bold;
        font-size: 18px;
        margin-top: 5px;
        .item_images {
          //   width: 100px;
          //   height: 24px;
          //   background: url("../../assets/images/tag.png") no-repeat;
          //   background-size: 100% 100%;
          //   color: white;
          //   padding-left: 3px;
          //   padding-top: 2px;
          //   margin-top: 10px;
          color: red;
          font-weight: bolder;
        }
        // :nth-child(1) {
        //   width: 300px;
        // }
        // :nth-child(2) {
        //   width: 300px;
        // }
      }
      .item_num {
        :nth-child(1) {
          width: 300px;
        }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
  .release {
    height: 40px;
    width: 200px;
    padding: 10px 20px;
    // border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
  }
}
.item_im {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item_time {
  margin-top: 10px;
  border-top: 1px solid black;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.submitCountNums {
  color: orange;
}
</style>
